import { useControl } from 'react-map-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';

export default function GeocodingControl(props) {
  useControl(
    () => {
      const control = new MapboxGeocoder({
        accessToken: props.accessToken,
        countries: 'US',
        proximity: ' -87.623177,41.881832',
      });

      control.on('loading', props.onLoading);
      control.on('results', props.onResults);
      control.on('result', (event) => {
        props.onResult(event);
      });

      if (document.querySelector('.mapboxgl-ctrl-geocoder')) {
        return;
      }

      if (document.getElementById('geocoder')) {
        document
          .getElementById('geocoder')
          .appendChild(control.onAdd(props.map));
      }
    },
    {
      position: props.position,
    }
  );
}

const noop = () => {};

GeocodingControl.defaultProps = {
  marker: true,
  onLoading: noop,
  onResults: noop,
  onResult: noop,
  onError: noop,
};
