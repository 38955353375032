import React, { useRef } from 'react';
import { Tabs } from 'react-bulma-components';
import DemographicTab from './DemographicTab';
import OfficeTab from './OfficeTab';

const WardTabs = ({ wardData, ward, candidates }) => {
  const { incumbent, contesting, is_retiring, is_running_for_mayor, winner } =
    wardData;
  const officeRef = useRef();
  const demoRef = useRef();

  const tabs = [
    {
      title: 'Office',
      key: 'office',
      ref: officeRef,
      icon: 'fas fa-user-tie',
    },
    {
      title: 'Demographics',
      key: 'demo',
      ref: demoRef,
      icon: 'fas fa-chart-bar',
    },
  ];

  return (
    <>
      {/* <Tabs>
        {tabs.map(({ key, title, icon, ref }) => (
          <Tabs.Tab
            key={key}
            className="arcon"
            onClick={() =>
              ref.current.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              })
            }
          >
            <button className="button is-info">
              <span className="icon">
                <i className={icon}></i>
              </span>
              <span>{title}</span>
            </button>
          </Tabs.Tab>
        ))}
      </Tabs> */}
      <div ref={officeRef} className="mb-5">
        <OfficeTab
          incumbent={incumbent}
          ward={ward}
          candidates={candidates}
          isRetiring={is_retiring}
          isRunningForMayor={is_running_for_mayor}
          isWinner={winner}
        />
      </div>
      <div ref={demoRef}>
        <DemographicTab ward={ward} />
      </div>
    </>
  );
};

export default WardTabs;
