import React, { useState } from 'react';

const Accordion = ({ title, content, children }) => {
  const [showContent, setShowContent] = useState(false);
  const isClickable = Boolean(content) ? 'is-clickable' : '';
  return (
    <div className="accordion-item">
      <div
        className={`p-2 is-flex is-justify-content-space-between ${isClickable} is-align-items-center accordion-title`}
        onClick={() => setShowContent(!showContent)}
      >
        {children || (
          <span className="avenir has-text-white is-size-5">{title}</span>
        )}
        {content && (
          <span className="icon">
            <i
              className={`fa ${
                showContent ? 'fa-chevron-up' : 'fa-chevron-down'
              }`}
            ></i>
          </span>
        )}
      </div>
      {showContent && content && (
        <div className="has-background-black p-3">
          <p
            className="avenir has-text-white"
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </div>
      )}
    </div>
  );
};

export default Accordion;
