import React from 'react';
import { Navbar } from 'react-bulma-components';
import { useMediaQuery } from 'react-responsive';

import TheTriibeLogo from '../assets/images/the-triibe-logo--white.png';
import { TabletAndDown, TabletAndUp } from './responsive';

const Header = (props) => {
  const { ward, district } = props;
  const isMobile = useMediaQuery({ maxWidth: 991 });

  return (
    <Navbar
      backgroundColor="black pl-3 pt-3"
      className={isMobile && 'is-mobile'}
    >
      <Navbar.Brand>
        <TabletAndUp>
          <Navbar.Item href="https://thetriibe.com">
            <img src={TheTriibeLogo} alt="The TRiiBE Logo" />
          </Navbar.Item>
        </TabletAndUp>
        <TabletAndDown>
          <Navbar.Item className="is-flex">
            <div className="has-background-black p-1 is-clickable mr-4">
              <p className="altero is-uppercase is-orange is-size-7">
                Your ward
              </p>
              <span className="silkscreen has-text-white is-size-4">
                {ward || '--'}
              </span>
            </div>
            <div className="has-background-black p-1 is-clickable">
              <p className="altero is-uppercase is-orange is-size-7">
                Your police district
              </p>
              <span className="silkscreen has-text-white is-size-4">
                {district || '--'}
              </span>
            </div>
          </Navbar.Item>
        </TabletAndDown>
      </Navbar.Brand>
    </Navbar>
  );
};;

export default Header;
