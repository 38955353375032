import React from 'react';
import districtMap from '../assets/data/districts-metadata.json';
import Accordion from './Accordion';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

const DistrictSummary = ({ district, candidates }) => {
  const { neighborhoods } = districtMap[district];

  return (
    <div className="has-background-grey-darker p-3 summary-column">
      <h2 className="altero is-uppercase has-text-white is-size-3 mb-5">
        Police District {district}
      </h2>
      <span className="altero has-text-info is-uppercase is-block mb-2">
        Neighborhoods
      </span>
      <p className="avenir mb-2">{neighborhoods}</p>
      <span className="altero has-text-info is-uppercase is-block mb-2">
        Candidates
      </span>
      <div className="is-flex is-flex-direction-column gap-1 mb-3">
        {candidates && candidates.length ? (
          candidates.map(({ candidateId, fullName, summary, elected }) => (
            <Accordion
              key={candidateId}
              title={fullName}
              content={documentToHtmlString(summary)}
            >
              {elected && (
                <>
                  <span className="avenir has-text-white is-size-5">{fullName}</span>  
                <div className="tag" style={{ backgroundColor: '#68B067'}}>
                    <span className="icon mr-2">
                      <i className="fa fa-crown has-text-warning"></i>
                    </span>
                  <span className="is-bold has-text-white">Elected</span>
                </div>
                </>
              )}
            </Accordion>
          ))
        ) : (
          <div className="has-background-black-ter p-4 avenir">
            <span className="icon mr-2 has-text-warning">
              <i className="fa fa-users-slash"></i>
            </span>
            There are no challengers.
          </div>
        )}
      </div>
    </div>
  );
};

export default DistrictSummary;
