import React, { useContext, useState } from 'react';

const MapContext = React.createContext();
const MapUpdateContext = React.createContext();

export function useMap() {
  return useContext(MapContext);
}

export function useMapUpdate() {
  return useContext(MapUpdateContext);
}

export function MapProvider({ children }) {
  const [mapRef, setMapRef] = useState();

  return (
    <MapContext.Provider value={mapRef}>
      <MapUpdateContext.Provider value={setMapRef}>
        {children}
      </MapUpdateContext.Provider>
    </MapContext.Provider>
  );
}
