import React from 'react';
import Sources from './Sources';

const BoundaryLookup = () => (
  <>
    <p className="altero is-orange is-uppercase is-size-4 has-text-centered mb-2">
      Ward // Police District Lookup
    </p>
    <p className="avenir mb-3 has-text-centered px-3">
      Enter your Chicago address below to get info on your ward and police
      district
    </p>
    <div id="geocoder" className="mb-3"></div>
    <hr />
    <article
      className="message is-danger is-hidden is-radiusless"
      id="js-boundary-lookup-error"
    >
      <div className="message-body arcon is-radiusless">
        The address you entered is not within the city of Chicago. Please try
        again.
      </div>
    </article>
  </>
);

export default BoundaryLookup;
