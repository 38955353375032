import React from 'react'

export default function WardUpdatePrompt({ hidePrompt }) {
    return (
        <div className={`console-overlay`}>
            <div className="has-background-black p-5" style={{ border: '2px solid #2F80ED'}}>
                <p className='altero is-uppercase has-text-white is-size-3 mb-2'>
                    We updated your ward result!
                </p>
                <p className='avenir has-text-white mb-2'>
                    We use multiple sources to make sure we provide accurate results.
                </p>
                <button className='button is-info altero' onClick={hidePrompt}>
                    OK
                </button>
            </div>
        </div>
    )
}