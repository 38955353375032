import React from 'react';
import wardMap from '../assets/data/ward-metadata.json';
import WardTabs from './WardTabs';

const WardSummary = ({ ward, candidates, handleScroll }) => {
  const { summary, incumbent } = wardMap[ward];

  let hasWinner = false;

  // if (incumbent.winner) {
  //   hasWinner = true
  // }

  candidates.forEach(({ elected }) => {
    if (elected) {
      hasWinner = true;
    }
  });

  return (
    <div
      className="has-background-grey-darker p-3 summary-column"
      onScroll={handleScroll}
    >
      <h2 className="altero is-uppercase has-text-white is-size-3 mb-2">
        <span>Ward {ward}</span>
        {!hasWinner && (
          <div
            className="tag ml-2"
            style={{ backgroundColor: '#FFED8E', color: 'black' }}
          >
            <span className="is-bold">Headed to runoff</span>
          </div>
        )}
      </h2>
      <p className="avenir has-text-white mb-5">{summary}</p>
      <WardTabs wardData={wardMap[ward]} ward={ward} candidates={candidates} />
    </div>
  );
};

export default WardSummary;
