import React, { useEffect } from 'react';
import { Columns, Message } from 'react-bulma-components';
import { useBoundaryType, useBoundaryUpdate } from '../context/boundaryContext';
import { useMap } from '../context/mapContext';
import { usePolling } from '../context/pollingContext';
import PollingLocation from './PollingLocation';
import { TabletAndDown, Desktop } from './responsive';

export default function UserBoundaries(props) {
  const { ward, district, prevWard, wardRef, districtRef, setShowMap } = props;
  const hasNewWard = ward !== prevWard;
  const pollingInfo = usePolling();
  const boundaryType = useBoundaryType();
  const setBoundaryView = useBoundaryUpdate();
  const map = useMap();

  useEffect(() => {
    if (!map || !wardRef || !districtRef) {
      return;
    }
    map.current.zoomTo(10, {
      duration: 200,
    });

    if (boundaryType === 'wards') {
      wardRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    } else {
      districtRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [boundaryType, map, wardRef, districtRef]);

  return (
    <>
      {/* <p className="avenir is-italic is-orange has-text-centered mb-2 has-text-weight-bold">
        <span className="icon mr-2 is-size-4">
          <i className="fa fa-hand-point-down"></i>
        </span>
        <span className="is-size-7">
          Click the boxes below to toggle the map boundaries
        </span>
      </p> */}
      <Columns gap={2}>
        <Columns.Column>
          <div
            onClick={() => setBoundaryView('wards')}
            className="p-3 boundary-toggle"
          >
            <p className="altero is-uppercase is-orange">Your ward</p>
            <span className="silkscreen has-text-white is-size-1">
              {ward || '--'}
            </span>
          </div>
        </Columns.Column>
        <Columns.Column>
          <div
            onClick={() => setBoundaryView('districts')}
            className="p-3 boundary-toggle"
          >
            <p className="altero is-uppercase is-orange">
              Your police district
            </p>
            <span className="silkscreen has-text-white is-size-1">
              {district || '--'}
            </span>
          </div>
        </Columns.Column>
      </Columns>
      {ward && <PollingLocation info={pollingInfo} />}
      {/* {prevWard && hasNewWard && (
        <Message color="info" className="is-radiusless">
          <Message.Body className="arcon is-radiusless">
            Before the latest map change, you were previously in{' '}
            <strong>Ward {prevWard}</strong>
          </Message.Body>
        </Message>
      )} */}
      {/* <Desktop>
        <button
          onClick={() => setShowMap(true)}
          className="button view-toggle avenir is-bold is-justify-content-center mt-2"
          style={{ width: '100%' }}
        >
          <span className="icon mr-2">
            <i className="fa fa-map"></i>
          </span>
          View map
        </button>
      </Desktop> */}
    </>
  );
}
