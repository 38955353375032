import React, { useContext, useState } from 'react';

const PollingContext = React.createContext();
const PollingUpdateContext = React.createContext();

export function usePolling() {
  return useContext(PollingContext);
}

export function usePollingUpdate() {
  return useContext(PollingUpdateContext);
}

export function PollingProvider({ children }) {
  const [mapRef, setMapRef] = useState({});

  return (
    <PollingContext.Provider value={mapRef}>
      <PollingUpdateContext.Provider value={setMapRef}>
        {children}
      </PollingUpdateContext.Provider>
    </PollingContext.Provider>
  );
}
