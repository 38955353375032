import React from 'react';

export default function Sources() {
  return (
    <>
      <p className="altero is-uppercase has-text-info mb-3">Sources</p>
      <ul className="avenir">
        <li>
          <a href="https://thetriibe.com/" className="is-italic">
            The TRiiBE
          </a>
        </li>
        <li>
          <a href="https://chicagoreader.com/" className="is-italic">
            The Chicago Reader
          </a>
        </li>
        <li>
          <a
            href="https://app.mydistricting.com/legdistricting/comments/plan/225/26"
            className="is-italic"
          >
            MyDistricting
          </a>
        </li>
        <li>
          <a
            href="https://media.apps.chicagotribune.com/ward-redistricting/index.html#41.837593215373566,-87.72386287453305,12,0"
            className="is-italic"
          >
            Chicago Tribune
          </a>
        </li>
      </ul>
    </>
  );
}
