import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import 'swiper/css';
import './App.css';
import './App.scss';

import React, { useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import Header from './components/Header';
import Map from './components/BoundaryMap';
import InfoConsole from './components/InfoConsole';
import { Desktop, TabletAndDown, Widget } from './components/responsive';
import BoundaryLookup from './components/BoundaryLookup';
import { BoundaryProvider, useBoundaryType } from './context/boundaryContext';
import { MapProvider } from './context/mapContext';
import { PollingProvider } from './context/pollingContext';
import WardUpdatePrompt from './components/WardUpdatePrompt';

const MapViewLabel = ({ mapShown, showMap }) => {
  const mapType = useBoundaryType();

  return (
    <div className="has-background-black silkscreen has-text-white is-size-3 has-text-weight-bold p-3 map-boundary-label is-flex gap-2 is-align-items-center">
      <span>{mapType === 'wards' ? 'Wards' : 'Police Districts'}</span>
      {mapShown && (
        <button
          onClick={() => showMap(false)}
          className="button view-toggle avenir is-bold is-justify-content-center"
          style={{ width: '100%' }}
        >
          <span className="icon mr-2">
            <i className="fa fa-window-maximize"></i>
          </span>
          View data
        </button>
      )}
    </div>
  );
};

function App() {
  const [currentUserWard, setCurrentUserWard] = useState(null);
  const [selectedWard, setSelectedWard] = useState(null);
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [showPrompt, setShowPrompt] = useState(false)
  const [userDistrict, setUserDistrict] = useState(null);
  const [prevUserWard, setPrevUserWard] = useState(null);
  const [mobileSwiper, setMobileSwiper] = useState(null);
  const [showMap, setShowMap] = useState(false);
  const sidebar = useRef(null);
  const columnHeight = window.innerHeight;

  return (
    <>
      <MapProvider>
        <BoundaryProvider>
          <PollingProvider>
            <Desktop>
              <div style={{ position: 'relative' }} className="is-h-full">
                <div className={`console-overlay ${showMap && 'is-hidden'}`}>
                  <div className="console" ref={sidebar}>
                    <Header />
                    <InfoConsole
                      currentUserWard={currentUserWard}
                      selectedWard={selectedWard}
                      selectedDistrict={selectedDistrict}
                      prevUserWard={prevUserWard}
                      userDistrict={userDistrict}
                      showMap={setShowMap}
                    />
                  </div>
                </div>
                {/* <MapViewLabel showMap={setShowMap} mapShown={showMap} /> */}
                <Map
                  sidebarRef={sidebar}
                  setCurrentUserWard={setCurrentUserWard}
                  setSelectedWard={setSelectedWard}
                  setSelectedDistrict={setSelectedDistrict}
                  setUserDistrict={setUserDistrict}
                  setPrevUserWard={setPrevUserWard}
                  showMap={setShowMap}
                  showPrompt={() => setShowPrompt(true)}
                />{' '}
              </div>
            </Desktop>
            <TabletAndDown>
              <Header
                ward={currentUserWard}
                prevWard={prevUserWard}
                district={userDistrict}
              />
              <div className="has-background-black" style={{ height: '100%' }}>
                <div style={{ height: 90 }}></div>
                <div className="ward-lookup has-text-white p-4">
                  <div className="p-4 has-background-grey-dark mb-3">
                    <BoundaryLookup />
                  </div>
                  {/* {selectedWard && (
                      <div className="is-flex is-justify-content-end">
                        <button
                          onClick={() => mobileSwiper.slideNext()}
                          className="button altero has-text-info is-uppercase has-background-black py-1 px-2 mb-2 is-size-5"
                        >
                          {`View Data >`}
                        </button>
                      </div>
                    )} */}
                </div>
                <InfoConsole
                  currentUserWard={currentUserWard}
                  selectedWard={selectedWard}
                  selectedDistrict={selectedDistrict}
                  userDistrict={userDistrict}
                />
                <div className="is-hidden">
                  <Map
                    sidebarRef={sidebar}
                    setCurrentUserWard={setCurrentUserWard}
                    setSelectedWard={setSelectedWard}
                    setSelectedDistrict={setSelectedDistrict}
                    setUserDistrict={setUserDistrict}
                    setPrevUserWard={setPrevUserWard}
                    showPrompt={() => setShowPrompt(true)}
                  />
                </div>
              </div>
              {/* <SwiperSlide>
                  <button
                    onClick={() => mobileSwiper.slidePrev()}
                    className="button altero has-text-info is-uppercase has-background-black py-1 px-2 mb-2 is-size-5 map-back-btn"
                  >
                    {`< Back`}
                  </button>
                  <Map
                    setCurrentUserWard={setCurrentUserWard}
                    setSelectedWard={setSelectedWard}
                    setSelectedDistrict={setSelectedDistrict}
                    setUserDistrict={setUserDistrict}
                    setPrevUserWard={setPrevUserWard}
                  />
                </SwiperSlide> */}
            </TabletAndDown>
            {
              showPrompt && (
                <WardUpdatePrompt hidePrompt={() => setShowPrompt(false)} />
              )
            }
          </PollingProvider>
        </BoundaryProvider>
      </MapProvider>
    </>
  );
}

export default App;
