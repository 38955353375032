import * as contentful from 'contentful';
const API_TOKEN = process.env.REACT_APP_CONTENTFUL_API_TOKEN;
const SPACE_ID = process.env.REACT_APP_CONTENTFUL_SPACE_ID;

const contentfulClient = contentful.createClient({
  space: SPACE_ID,
  accessToken: API_TOKEN,
});

export const getCandidates = async () => {
  try {
    const { items: entries } = await contentfulClient.getEntries({
      content_type: 'candidate',
      limit: 400,
    });

    const candidateEntries = entries.reduce(
      (acc, entry) => {
        const { district, ward } = entry.fields;

        const candidateData = {
          candidateId: entry.sys.id,
          _id: entry.sys.id,
          ...entry.fields,
        };

        if (district) {
          if (acc.districts[district]) {
            acc.districts[district].push(candidateData);
          } else {
            acc.districts[district] = [candidateData];
          }
        }

        if (ward) {
          if (acc.wards[ward]) {
            acc.wards[ward].push(candidateData);
          } else {
            acc.wards[ward] = [candidateData];
          }
        }

        return acc;
      },
      { districts: {}, wards: {} }
    );

    return candidateEntries;
  } catch (err) {
    return false;
  }
};
