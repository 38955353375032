import React from 'react';
import { Progress } from 'react-bulma-components';
import demoJson from '../../assets/data/ward-demographics.json';

const DemographicTab = ({ ward }) => {
  const { total_population, demographics } = demoJson[ward];

  const converStringToNum = (value) => {
    if (typeof value === 'string') {
      return parseInt(value.replace(/,/g, ''));
    }

    return value;
  };

  demographics.sort((a, b) => {
    return converStringToNum(b.numeric) - converStringToNum(a.numeric);
  });


  return (
    <>
      <h2 className="altero is-uppercase is-size-4 section-header">
        Demographics
      </h2>
      <div className="p-3 is-flex is-flex-direction-column has-background-black-ter mb-3">
        <span className="altero has-text-info is-uppercase">Total Pop.</span>
        <span className="arcon has-text-white is-size-4">
          {total_population}
        </span>
      </div>
      {demographics.map((data) => (
        <div
          key={data.race}
          className="p-3 is-flex is-flex-direction-column has-background-black-ter mb-3 gap-1"
        >
          <small className="avenir has-text-white is-bold is-size-5">
            {data.race} - {data.percent}
          </small>
          <Progress
            max={100}
            size="large"
            value={
              (converStringToNum(data.numeric) /
                converStringToNum(total_population)) *
              100
            }
            color="info"
          />
        </div>
      ))}
    </>
  );
};

export default DemographicTab;
