import React from 'react';
import Accordion from '../Accordion';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

const ElectedBadge = ({status}) => {
  let text;
  let style = {
    backgroundColor: '#68B067',
    color: 'white'
  }

  switch (status) {
    case 1:
      text = 'Newly elected';
      break;
    case 2:
      text = 'Re-elected';
      break;
    case 3:
      text = 'Headed to runoff';
      style.backgroundColor = '#FFED8E'
      style.color = 'black'
      break;
    default:
      text = '';
      break;
  }

  return (
    <div className="tag" style={style}>
      {status !== 3 && (
        <span className="icon mr-2">
          <i className="fa fa-crown has-text-warning"></i>
        </span>
      )}
      <span className='is-bold'>{text}</span>
    </div>
  );
};

const CandidateCard = ({ name, winner }) => (
  <div className="has-background-black-ter p-2">
    {winner === name && (
      <span className="icon mr-2">
        <i className="fa fa-crown has-text-warning"></i>
      </span>
    )}
    <span className="arcon has-text-white">{name}</span>
  </div>
);

const OfficeTab = ({ candidates }) => {
  let incumbent = {};
  let contesting = [];

  if (candidates) {
    contesting = candidates.filter((person) => {
      if (person.isIncumbent) {
        incumbent = person;
        return false;
      }

      return true;
    });
  }

  return (
    <>
      <h2 className="is-size-4 section-header is-flex is-align-items-center">
        <span className="altero is-uppercase">Alderperson</span>
        {/* <div className="is-size-7 avenir">
          <span className="icon mr-2">
            <i className="fa fa-crown has-text-warning"></i>
          </span>
          = Newly appointed
        </div> */}
      </h2>
      <div className="p-3 is-flex is-flex-direction-column has-background-black-ter mb-3">
        <Accordion
          title={incumbent.fullName}
          content={documentToHtmlString(incumbent.summary)}
        >
          <div>
            <div className="altero has-text-info is-uppercase">Incumbent</div>
            <div className="avenir has-text-white is-size-4 is-flex gap-1 is-align-items-center">
              <span>{incumbent.fullName}</span>
              {incumbent.isRetiring && (
                <div className="tag is-danger avenir has-text-weight-bold">
                  retiring
                </div>
              )}
              {incumbent.isMayoral && (
                <div className="tag is-warning avenir has-text-weight-bold">
                  running for mayor
                </div>
              )}
              {incumbent.elected && <ElectedBadge status={incumbent.elected} />}
            </div>
          </div>
        </Accordion>
      </div>
      <span className="altero has-text-info is-uppercase is-block mb-2">
        Contesting candidates
      </span>
      <div className="is-flex is-flex-direction-column gap-1 mb-3">
        {contesting.length ? (
          contesting.map(({ candidateId, fullName, summary }) => (
            <Accordion
              key={candidateId}
              title={fullName}
              content={documentToHtmlString(summary)}
            />
          ))
        ) : (
          <div className="has-background-black-ter p-4 avenir">
            <span className="icon mr-2 has-text-warning">
              <i className="fa fa-users-slash"></i>
            </span>
            There were no challengers.
          </div>
        )}
      </div>
    </>
  );
};

export default OfficeTab;
