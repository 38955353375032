import '../App.css';
import '../App.scss';
import voteMarkerIcon from '../assets/images/vote-marker.png';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

import React, { useRef, useState, useCallback, useEffect } from 'react';
import { Map, Source, Layer, Popup, Marker } from 'react-map-gl';
import { useMediaQuery } from 'react-responsive';
import { centerOfMass, booleanWithin, booleanPointInPolygon, point, polygon, bbox } from '@turf/turf';
import mapboxgl from 'mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import MapboxWorker from 'worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker'; // eslint-disable-line import/no-webpack-loader-syntax
import GeocodingControl from './GeocodingControl';

import districtGeoJson from '../assets/data/boundaries-cpd-police-districts.geojson';
import wards2023GeoJson from '../assets/data/boundaries-chicago-wards-2023.geojson';
import { useBoundaryType } from '../context/boundaryContext';
import { useSwiper } from 'swiper/react';
import { useMapUpdate } from '../context/mapContext';
import { usePolling, usePollingUpdate } from '../context/pollingContext';

mapboxgl.workerClass = MapboxWorker;
const accessToken =
  'pk.eyJ1IjoidGhldHJpaWJlIiwiYSI6ImNsN2lrNXpvbzBwNmwzdnFwMmplbGU0Y2MifQ.FWKjKphcH_jEi3yIOJLpYw';

const sources = {
  districts: {
    id: 'districts-layer',
    data: districtGeoJson,
  },
  wards: {
    id: 'wards-layer',
    data: wards2023GeoJson,
  },
};

const mapInitialState = {
  longitude: -87.623177,
  latitude: 41.881832,
  minZoom: 10,
};

const boundaryFillStyle = {
  id: 'boundary-fill',
  type: 'fill',
  paint: {
    'fill-color': '#2F80ED',
    'fill-opacity': 0.5,
  },
};

const outlineStyle = {
  id: 'outline',
  type: 'line',
  paint: {
    'line-color': [
      'case',
      ['boolean', ['feature-state', 'hover'], false],
      '#FF8E6A',
      '#1d4171',
    ],
    'line-width': [
      'case',
      ['boolean', ['feature-state', 'hover'], false],
      4,
      1,
    ],
  },
};

let hoveredStateId = null;
let currentFeatureId = null;
let userWard = null;

const BoundaryMap = ({
  setCurrentUserWard,
  setSelectedWard,
  setSelectedDistrict,
  setPrevUserWard,
  setUserDistrict,
  showMap,
  showPrompt,
  sidebarRef,
}) => {
  const map = useRef(null);
  const mapType = useBoundaryType();
  const setMap = useMapUpdate();
  const pollingLocation = usePolling();
  const setPollingLocation = usePollingUpdate();
  const [showWardPopup, setShowWardPopup] = useState(false);
  const [showPollingMarker, setShowPollingMarker] = useState(false);
  const [popupData, setPopupData] = useState({});
  const [showPollingPopup, setShowPollingPopup] = useState(false);
  const [markerData, setMarkerData] = useState({});
  const [sourceData, setSourceData] = useState(sources.wards.data);

  const swiper = useSwiper();
  const isMobile = useMediaQuery({ maxWidth: 991 });

  const onMapLoad = useCallback(() => {
    map.current.on('mousemove', 'boundary-fill', (e) => {
      if (e.features.length > 0) {
        const currentFeature = e.features[0];

        if (userWard === parseInt(currentFeature.properties.ward)) {
          hoveredStateId = null;
          return;
        }

        if (hoveredStateId !== null) {
          map.current.setFeatureState(
            {
              source: 'chicago-boundaries',
              id: hoveredStateId,
            },
            { hover: false }
          );
        }

        hoveredStateId = currentFeature.id;

        map.current.setFeatureState(
          { source: 'chicago-boundaries', id: hoveredStateId },
          { hover: true }
        );

        if (showWardPopup) {
          return;
        } else {
          const featureCenter = centerOfMass(currentFeature);
          const {
            geometry: { coordinates },
          } = featureCenter;
          const [longitude, latitude] = coordinates;

          const { dist_num, ward } = currentFeature.properties;

          const boundaryNumber = dist_num || ward;

          const html = `
            <span class="silkscreen">${dist_num ? 'District' : 'Ward'}</span>
            <span class="silkscreen is-bold">${boundaryNumber}</span>
          `;

          setPopupData({
            html,
            longitude,
            latitude,
          });
          setShowWardPopup(true);
        }
      }
    });

    map.current.on('mouseleave', 'boundary-fill', (e) => {
      if (hoveredStateId !== null && hoveredStateId !== currentFeatureId) {
        map.current.setFeatureState(
          { source: 'chicago-boundaries', id: hoveredStateId },
          { hover: false }
        );
      }
      hoveredStateId = null;
      setPopupData(null);
      setShowWardPopup(false);
    });

    map.current.on('click', 'boundary-fill', (e) => {
      const { ward, dist_num } = e.features[0].properties;
      if (ward) {
        setSelectedWard(parseInt(ward));
      }

      if (dist_num) {
        setSelectedDistrict(parseInt(dist_num));
      }

      if (isMobile) {
        swiper.slideNext();
      }

      showMap(false);
    });

    setMap(map);
  }, [
    showWardPopup,
    showMap,
    swiper,
    isMobile,
    setSelectedDistrict,
    setSelectedWard,
    setMap,
  ]);

  useEffect(() => {
    setSourceData(sources[mapType].data);
  }, [mapType]);

  function getWardFeature(wardNum) {
    // const features = map.current.querySourceFeatures('chicago-boundaries', {
    //   sourceLayer: 'wards-layer',
    // });
    const features = map.current.querySourceFeatures('chicago-boundaries');

    const [matchedFeature] = features.filter(
      ({ properties }) => parseInt(properties.ward) === wardNum
    );

    return matchedFeature
  }

  function highlightWard(wardNum) {
    const matchedFeature = getWardFeature(wardNum)

    const { id } = matchedFeature;
    map.current.setFeatureState(
      { source: 'chicago-boundaries', id },
      { hover: true }
    );
    currentFeatureId = id;
  }

  function highlightDistrict(districtNum) {
    const features = map.current.querySourceFeatures('chicago-boundaries', {
      sourceLayer: 'districts-layer',
    });

    const [matchedFeature] = features.filter(
      ({ properties }) => parseInt(properties.dist_num) === districtNum
    );

    const { id } = matchedFeature;
    map.current.setFeatureState(
      { source: 'chicago-boundaries', id },
      { hover: true }
    );
    currentFeatureId = id;
  }

  function unhighlightWard(featureId) {
    map.current.setFeatureState(
      { source: 'chicago-boundaries', id: featureId },
      { hover: false }
    );
  }

  function validateIsInWard({ lat, lon }, feature) {
    console.log(lat,lon)
    console.log(feature);
    console.log(bbox(feature));
    const featureBbox = bbox(feature)
    const isInWard = new mapboxgl.LngLatBounds(featureBbox).contains(new mapboxgl.LngLat(lon, lat))
    console.log(isInWard);
    // const isInWard = booleanWithin(point([lon, lat]), feature._geometry)
    // const isInWard = booleanPointInPolygon(point([lon, lat]), feature._geometry)
    // console.log(isInWard)
  }

  async function onResult(event) {
    const { result } = event;
    const { center, address, text } = result;
    const [lon, lat] = center;

    try {
      if (currentFeatureId) {
        unhighlightWard(currentFeatureId);
      }

      const lookupErr = document.getElementById('js-boundary-lookup-error');
      lookupErr.classList.add('is-hidden');

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/election/ward?lat=${lat}&lon=${lon}&include=wards-2015,cpd-districts`
      );
      const { data } = await response.json();
      const { ward, ward_2015, cpd_district } = data[0];

      if (!ward && !cpd_district) {
        lookupErr.classList.remove('is-hidden');
      }

      setCurrentUserWard(parseInt(ward.ward));
      userWard = parseInt(ward.ward);
      setUserDistrict(parseInt(cpd_district.dist_num));
      setSelectedWard(parseInt(ward.ward));
      setSelectedDistrict(parseInt(cpd_district.dist_num));
      setPrevUserWard(parseInt(ward_2015.ward));

      const innerSidebar = sidebarRef.current.children[1];

      setTimeout(() => {
        window.parent.postMessage(
          innerSidebar.scrollHeight,
          'https://thetriibe.com'
        );
        sidebarRef.current.style.height = '100%';
      }, 500);

      // validateIsInWard({ lat, lon }, getWardFeature(parseInt(ward.ward)))

      if (!isMobile) {
        if (mapType === 'wards') {
          // highlightWard(parseInt(ward.ward));
        } else if (mapType === 'districts') {
          highlightDistrict(parseInt(cpd_district.dist_num));
        }
      }

      // if (isMobile) {
      //   swiper.slideNext();
      // }

      // map.current.panTo([lon, lat], {
      //   duration: 2000,
      // });

      if (!isMobile) {
        // setTimeout(() => {
        //   map.current.zoomTo(13, {
        //     duration: 1000,
        //   });
        // }, 2000);
      }
    } catch (err) {
      console.log(err);
    }

    try {
      await getPrecinct(address, text);
    } catch (err) {
      console.log(err);
    }
    
    try {
      await validateWard(address, text)
    } catch(err) {
      console.log(err)
    }

  }

  async function getPrecinct(streetNum, addressString) {
    setPollingLocation({});

    const parsedAddress = addressString.split(' ');
    const streetDirection = parsedAddress[0];
    const streetSuffix = parsedAddress.at(-1);
    const streetName = parsedAddress.slice(1, -1).join(' ');
    const body = {
      streetNum,
      streetDirection,
      streetName,
      streetSuffix,
    };

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/election/polling`,
      {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify(body),
      }
    );

    const { data } = await response.json();
    const { address } = data[0];

    setPollingLocation(data[0]);

    const positionStackApiKey = process.env.REACT_APP_POSITIONSTACK_API_KEY;

    const response2 = await fetch(
      `http://api.positionstack.com/v1/forward?access_key=${positionStackApiKey}&query=${address},Chicago,IL&limit=1`
    );

    const { data: data2 } = await response2.json();
    const [result] = data2;
    const { latitude, longitude } = result;

    map.current.panTo([longitude, latitude]);
    setMarkerData({
      latitude,
      longitude,
    });
    setShowPollingMarker(true);
  }

  async function validateWard(streetNum, addressString) {
    const parsedAddress = addressString.split(' ');
    const streetDirection = parsedAddress[0];
    const streetSuffix = parsedAddress.at(-1);
    const streetName = parsedAddress.slice(1, -1).join(' ');
    const body = {
      streetNum,
      streetDirection,
      streetName,
      streetSuffix,
    };

    const response = await fetch(
      `${process.env.REACT_APP_API_URL}/election/ward`,
      {
        method: 'POST',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify(body),
      }
    );

    const { data: ward } = await response.json();

    if (!ward) {
      const lookupErr = document.getElementById('js-boundary-lookup-error');
      lookupErr.classList.remove('is-hidden');
    } else if (parseInt(ward) !== userWard) {
      userWard = parseInt(ward);
      setCurrentUserWard(parseInt(ward));
      setSelectedWard(parseInt(ward));
      showPrompt()
    }
  }

  return (
    <Map
      ref={map}
      initialViewState={mapInitialState}
      style={{ height: '100%', width: '100%' }}
      mapStyle="mapbox://styles/mapbox/dark-v10"
      mapboxAccessToken={accessToken}
      onLoad={onMapLoad}
    >
      {showWardPopup && (
        <Popup
          anchor="bottom"
          closeButton={false}
          longitude={popupData.longitude}
          latitude={popupData.latitude}
        >
          <div dangerouslySetInnerHTML={{ __html: popupData.html }} />
        </Popup>
      )}
      {showPollingMarker && (
        <Marker
          latitude={markerData.latitude}
          longitude={markerData.longitude}
          onClick={() => setShowPollingPopup(true)}
        >
          <img src={voteMarkerIcon} alt="" />
        </Marker>
      )}
      {/* {showPollingPopup && (
        <Popup
          anchor="bottom"
          offset={30}
          closeButton={false}
          longitude={markerData.longitude}
          latitude={markerData.latitude}
        >
          <div className="arcon is-size-5 has-text-centered">
            {pollingLocation.location}
          </div>
        </Popup>
      )} */}
      <Source
        id="chicago-boundaries"
        type="geojson"
        data={sourceData}
        generateId
      >
        <Layer {...boundaryFillStyle} />
        <Layer {...outlineStyle} />
      </Source>
      <GeocodingControl
        onResult={onResult}
        accessToken={accessToken}
        position="top-left"
      />
    </Map>
  );
};

export default BoundaryMap;
