import React from 'react';

const PollingLocation = ({ info }) => {
  const { location, address } = info;
  return (
    <div className="has-background-black">
      <div className="tri-color-border">
        <div className="bg-red"></div>
        <div className="bg-blue"></div>
        <div className="bg-white"></div>
      </div>
      <div className="p-5 avenir">
        <p className="altero title is-size-6 is-uppercase has-text-info mb-2">
          Your polling location
        </p>
        {info.location ? (
          <>
            <p className="is-bold is-size-4">{location}</p>
            <address>{address}</address>
          </>
        ) : (
          <div className="is-flex is-align-items-center">
            <button className="button is-text is-loading mr-2" />
            <span className="avenir is-italic">
              Fetching your polling center
            </span>
          </div>
        )}
        <hr className="my-2" />
        <p className="avenir is-size-7 is-italic has-text-grey">
          powered by{' '}
          <a href="https://www.chicagoelections.gov/en/your-voter-information.html">
            Chicago Board of Elections
          </a>
        </p>
      </div>
    </div>
  );
};

export default PollingLocation;
