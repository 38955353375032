import React, { useRef, useState } from 'react';
import { useSwiper } from 'swiper/react';
import { getCandidates } from '../api/contentful';
import { usePolling } from '../context/pollingContext';
import BoundaryLookup from './BoundaryLookup';
import DistrictSummary from './DistrictSummary';
import PollingLocation from './PollingLocation';
import { Desktop, TabletAndDown } from './responsive';
import Sources from './Sources';
import UserBoundaries from './UserBoundaries';
import WardSummary from './WardSummary';

const InfoConsole = ({
  currentUserWard,
  selectedWard,
  selectedDistrict,
  prevUserWard,
  userDistrict,
  showMap,
}) => {
  const swiper = useSwiper();
  const ctaRef = useRef();
  const pollingInfo = usePolling();
  const scrollRef = useRef();
  const wardRef = useRef();
  const districtRef = useRef();

  const [firstView, setFirstView] = useState(true);
  const [districtCandidates, setDistrictCandidates] = useState(null);
  const [wardCandidates, setWardCandidates] = useState(null);

  async function fetchCandidates() {
    const { districts, wards } = await getCandidates();
    setDistrictCandidates(districts);
    setWardCandidates(wards);
  }

  const handleScroll = (e) => {
    if (!firstView) {
      return;
    }

    setFirstView(false);
  };

  if (!districtCandidates) {
    fetchCandidates();
  }

  return (
    <div
      className={`has-background-black p-5 has-text-white info-console ${
        selectedWard ? 'has-fetched' : ''
      }`}
      ref={scrollRef}
    >
      <Desktop>
        <div className="is-flex-1">
          <div className="p-4 has-background-grey-dark mb-3">
            <BoundaryLookup />
            <UserBoundaries
              wardRef={wardRef}
              districtRef={districtRef}
              ward={currentUserWard}
              prevWard={prevUserWard}
              district={userDistrict}
              setShowMap={showMap}
            />
          </div>
          <hr />
          <Sources />
        </div>
      </Desktop>
      <TabletAndDown>
        {selectedWard && (
          <div className="has-background-grey-dark p-2 mb-2">
            <PollingLocation info={pollingInfo} />
          </div>
        )}
      </TabletAndDown>
      <div className="is-flex-1 mb-2" ref={wardRef}>
        {selectedWard && (
          <WardSummary
            handleScroll={handleScroll}
            ward={selectedWard}
            candidates={wardCandidates[selectedWard]}
          />
        )}
        <Desktop>
          {selectedWard && firstView && (
            <div ref={ctaRef} className="scroll-cta">
              <div className="tag is-info floating avenir has-text-weight-bold">
                Scroll to view more
              </div>
            </div>
          )}
        </Desktop>
      </div>
      <div className="is-flex-1" ref={districtRef}>
        {selectedDistrict && (
          <DistrictSummary
            district={selectedDistrict}
            candidates={districtCandidates[selectedDistrict]}
          />
        )}
      </div>
      <TabletAndDown>
        <hr />
        <Sources />
      </TabletAndDown>
    </div>
  );
};

export default InfoConsole;
