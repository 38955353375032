import React, { useContext, useState } from 'react';

const BoundaryContext = React.createContext();
const BoundaryUpdateContext = React.createContext();

export function useBoundaryType() {
  return useContext(BoundaryContext);
}

export function useBoundaryUpdate() {
  return useContext(BoundaryUpdateContext);
}

export function BoundaryProvider({ children }) {
  const [boundaryType, setBoundaryType] = useState('wards');

  return (
    <BoundaryContext.Provider value={boundaryType}>
      <BoundaryUpdateContext.Provider value={setBoundaryType}>
        {children}
      </BoundaryUpdateContext.Provider>
    </BoundaryContext.Provider>
  );
}
